import React from "react";
import styled from "styled-components"


const WrapperDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  margin: 40px 0;

  img {
    margin: 10px;
    max-width: 46%;

    &:only-child {
      max-width: 100%;
    }
  }
`;

class GalleryBlock extends React.Component {
    constructor(props) {
        super();
      }

      render() {
        return (
           <WrapperDiv>
            { this.props.content.images != null && 
              <>
                  {this.props.content.images.map(image => <img src={image.asset.url}/>)}
              </>
              }
            </WrapperDiv>
        )
      }
}

export default GalleryBlock