import React, { useState } from 'react';
import { graphql } from 'gatsby'
import "../assets/index.css"
import Layout from "../layouts/layout"
import styled from "styled-components"
import Img from "gatsby-image"
import HeroBase from "../components/hero-base"
import TextBlock from "../components/text-block"
import GalleryBlock from "../components/gallery-block"
import TextImageBlock from "../components/text-image-block"
import VideoBlock from "../components/video-block"
import RecentPosts from "../components/recent-posts"
import LinkedInSVG from "../images/linkedIn.svg"
import TwitterSVG from "../images/twitter.svg"
import CopyLinkSVG from "../images/link.svg"
import CopiedLinkSVG from "../images/copied.svg"

export const query = graphql`
  query BlogTemplateQuery($id: String!) {
    category: sanityBlog(id: {eq: $id}) {
        title
        hide_title
        date(formatString: "MMMM Do, YYYY")
        use_large_hero
        author {
            first_name
            last_name
            image {
                asset {
                    fixed(width: 50, height: 50) {
                        ...GatsbySanityImageFixed_noBase64
                    }
                }
            }
        }
        slug {
            current
        }
        social_description
        social_title
        twitter_image {
          asset {
            url
          }
        }
        og_image {
          asset {
            url
          }
        }
        blogBuilder {
            ... on SanityTextBlock {
                text_Block
                list
                name
            }
            ... on SanityVideo {
                url
            }
            ... on SanityGallery {
                images {
                  asset {
                    url
                  }
                }
            }
            ... on SanityTextWithIllustration {
                heading
                image {
                  asset {
                    url
                  }
                }
                excerpt
                tagline
            }
            ... on SanityHero {
             title
            heading
            subline
            video_url
            background_color
            image {
                asset {
                    url
                }
            }
            background_image {
                asset {
                    fluid(maxWidth: 1920) {
                        ...GatsbySanityImageFluid
                    }
                }
              }
            }
        }
    }
  }
`

const BlogPostTemplate = props => {
    const { data = {}} = props
    const { title, hide_title, date, slug, author, use_large_hero, blogBuilder, social_description, social_title, twitter_image, og_image} = data.category || {}

    const WrapperDiv = styled.div`
        max-width: 1080px;
        margin: 0 auto 40px;
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        margin-bottom: 160px;
    `;

    const ListingContainer = styled.div`
        display: flex;
        box-sizing: border-box;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 40px;
    `

    const ListingLeft = styled.div`
        width: 70%;
        padding-right: 22px;

        @media only screen and (max-width: 1024px) {
            width: 100%;
            padding: 0px;
        }

        .back-to-blog {
            color: var(--text-grey);
            width: 100%;

            svg {
                transform: rotate(180deg);
                height: 12px;
                margin-right: 5px;
            }
        }
    `

    const ListingRight = styled.div`
        width: 30%;
        padding-left: 22px;
        padding-top: 26px;

        @media only screen and (max-width: 1024px) {
            width: 100%;
            padding: 0 20px;
            margin-top: 0px;
            padding-left: 0;
        }
    `

    const Flex = styled.div `
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    `

    const AuthorCard = styled.div`
        width: 70%;
        margin-bottom: 60px;
        color: var(--text-grey);
        display: flex;
        align-items: center;

        span {
            padding-left: 20px;
        }

        @media only screen and (max-width: 700px) {
            width: 100%;
            margin-bottom: 20px;
            flex-wrap: wrap;

            .author-image {
                width: 30px!important;
                height: 30px!important;

            }

            span {
                padding-left: 10px;
                font-size: 12px;
            }
        }
    `

    const ShareCard = styled.div`
        width: 30%;
        margin-bottom: 60px;
        color: var(--text-grey);
        display: flex;
        align-items: center;

        span {
            padding-left: 20px;
        }

        img {
            margin-bottom: 0;
            padding-left: 10px;
        }

        a {
            line-height: auto;
        }

        @media only screen and (max-width: 700px) {
            width: 100%;
            margin-bottom: 20px;

            span {
                padding-left: 0px;
            }

        }
    `

const chrevonSVG = () => (
    <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1">
        <title>Chevron</title>
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
            <g id="Link-/-LEARN-MORE" transform="translate(-118.000000, -2.000000)" stroke="#6A7C92" stroke-width="2">
                <polyline id="Chevron" transform="translate(122.000000, 9.000000) rotate(-90.000000) translate(-122.000000, -9.000000) " points="116 6 122.238234 12 128 6"></polyline>
            </g>
        </g>
    </svg>
);
  const [copySuccess, setCopySuccess] = useState('');

  function copyToClipboard(e) {
    e.target.focus();
    navigator.clipboard.writeText("https://laddpartners.com/company/blog/" + slug.current + "?utm_source=copied")
    setCopySuccess(true);

    setTimeout(function(){
        setCopySuccess(false);
    },5000)
  };

    
    function renderBlog(data, getHero) { 
        let content = [];

        if(getHero === true) {
            for (let idx in data) {
                const item = data[idx];
                if(item.__typename === "SanityHero") {
                    let class_name = "small-mobile"; 
                    if(use_large_hero === true) {
                        class_name = "large-mobile"; 
                    }
                    content.push(<HeroBase hero={item} mobileHero={class_name}></HeroBase>);
                } 
            }
        } else {
            for (let idx in data) {
                const item = data[idx];

                if(item.__typename === "SanityTextBlock") {
                    content.push(<TextBlock content={item}></TextBlock>);
                } 

                if(item.__typename === "SanityGallery") {
                    content.push(<GalleryBlock content={item}></GalleryBlock>);
                } 

                if(item.__typename === "SanityTextWithIllustration") {
                    content.push(<TextImageBlock content={item}></TextImageBlock>);
                }
                
                if(item.__typename === "SanityVideo") {
                    content.push(<VideoBlock content={item}></VideoBlock>);
                }
            }
        }
        
        return content;
    };

    return (
        <Layout title={title + " | Ladd Partners"} social_description={social_description} social_title={social_title} twitter_image_url={twitter_image} og_image_url={og_image}>
            {renderBlog(blogBuilder, true)}
            <WrapperDiv>
                <ListingContainer>
                    <ListingLeft>
                        { title !== null && hide_title !== true && 
                            <>
                            <a href="/company/blog" className="back-to-blog"><strong>{chrevonSVG()} The Learned Ladd</strong></a>
                            <h2 className="margin-top-10">{title}</h2>
                            </>
                        }
                        <Flex>
                        { author !== null && 
                            <AuthorCard>
                                <Img fixed={author.image.asset.fixed} className="author-image"/>
                                <span><strong>{author.first_name} {author.last_name}</strong> | {date}</span>                           
                            </AuthorCard>
                        }

                            <ShareCard>
                                <span>Share {copySuccess}</span>
                                <a href={"https://www.linkedin.com/shareArticle?mini=true&title=" + title + "&url=https://laddpartners.com/company/blog/" + slug.current} target="_blank"> 
                                    <img src={LinkedInSVG} alt="Linked In Logo" id="linkedin"/>
                                </a>
                                <a href={"https://twitter.com/intent/tweet?text=" + title + "&url=https://laddpartners/company/blog/" + slug.current} target="_blank"> 
                                    <img src={TwitterSVG} alt="X Formally Twitter Logo" id="twitter"/>
                                </a>

                                <a href="javascript:void(0)" onClick={copyToClipboard}>
                                    <img src={copySuccess === true ? CopiedLinkSVG : CopyLinkSVG} alt="Copy Link Icon" id="copy"/>
                                </a>
                            </ShareCard>
                        </Flex>

                        
                        {renderBlog(blogBuilder, false)}
                    </ListingLeft>
                    <ListingRight>
                        <RecentPosts currentPost={title}/>
                    </ListingRight>
                </ListingContainer>
            </WrapperDiv>
        </Layout>
    )
}

export default BlogPostTemplate