import React from "react";

function display(props) {
  let content = [];

  if(props.content.list === true) {
    content.push(props.content.text_Block.map(text => <li key={text.id}>{ text }</li>))
  } else {
    content.push(props.content.text_Block.map(text => <p key={text.id}>{ text }</p>))
  }
  return content;
}


class TextBlock extends React.Component {
    constructor(props) {
        super();
      }
      
      render() {
        return (
           <>

            { this.props.content.text_Block != null && this.props.content.list !== true && 
            <>
               {display(this.props)}
            </>
            }

            { this.props.content.text_Block != null && this.props.content.list === true && 
            <>
              <ul>
                {display(this.props)}
               </ul>
            </>
            }
            </>
        )
      }
}

export default TextBlock