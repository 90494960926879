import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

const WrapperDiv = styled.div`
    max-width: 1110px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-bottom: 140px;

    @media only screen and (max-width: 1024px) {
        margin-bottom: 20px;
        padding: 0;

    }

    > p {
        max-width: 1015px;
    }

    h3 {
        width: 100%;
    }
`;

const PostingsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &#less-than-two{
        justify-content: flex-start;
    }
`;

const MoreInfo = styled(Link)`
    font-size: 16px;
    font-weight: 600;
    color: #23C0F5;
    align-self: flex-end;
    width: 100%;
`

const query = graphql`
    query {
        allSanityBlog(sort: {fields: [date], order: DESC}, limit: 5) {
            totalCount
            edges {
              node {
                slug {
                    current
                }
                title
              }
            }
          }
      } 
`

const Postings = ({post}) => (
    <MoreInfo to={getPostUrl(post.slug.current)}>
      <p className="margin-bottom-10"><strong>{post.title}</strong></p>
    </MoreInfo>
)

function getPostUrl(slug) {
    return `/company/blog/${slug}`;
}
function getPostings(data, currentProps) {
    const PostingsArray = [];
    data.allSanityBlog.edges.forEach(item => {
        if(item.node.title != currentProps ) {
          PostingsArray.push(<Postings post={item.node} key={item.node.id}></Postings>)
        }
    });
    return PostingsArray;
}

export default (props) => (
    <StaticQuery
        query={query}
        render={data => {
            return (
                <>
                <WrapperDiv id="listings">
                <PostingsContainer id={data.allSanityBlog.totalCount <= '2' ? 'less-than-two' : ''}>
                    { data.allSanityBlog.totalCount > 1 && 
                      <>
                      <h4>Recent Posts</h4>
                      </>
                      }
                    {getPostings(data, props.currentPost)}
                </PostingsContainer> 
                </WrapperDiv>
                </>
            )
        }}
    />
)
