import React from "react";
import styled from "styled-components"

const WrapperDiv = styled.div`
  img {
    margin: 40px 0;
  }
`;


class TextImageBlock extends React.Component {
    constructor(props) {
        super();
      }

      render() {
        return (
           <WrapperDiv>
           { this.props.content.heading != null && 
              <>
              <h3 className="margin-bottom-20">{this.props.content.heading}</h3>
              </>
            }
            { this.props.content.tagline != null && 
              <>
              <h4 className="margin-bottom-10">{this.props.content.tagline}</h4>
              </>
            }

            { this.props.content.excerpt != null && 
              <>
              <p>{this.props.content.excerpt}</p>
              </>
            }
            
            { this.props.content.image != null && 
              <>
                <img src={this.props.content.image.asset.url}/>
              </>
            }
            </WrapperDiv>
        )
      }
}

export default TextImageBlock