import React from "react";
import styled from "styled-components"

const VideoIFrame = styled.iframe `
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 40px 0;
    border: 0;

    @media only screen and (max-width: 1140px) {
        width: 100%;
        max-width: 100%;
    }
`;
class TextBlock extends React.Component {
    constructor(props) {
        super();
      }
      
      render() {
        return (
           <>

            { this.props.content.url != null && 
            <>
              <VideoIFrame src={this.props.content.url} className="videoIframe" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></VideoIFrame>
            </>
            }
            </>
        )
      }
}

export default TextBlock